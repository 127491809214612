@import "../../../styles/variables.scss";
@import "../../../styles/helpers.scss";
@import "../../../styles/mixins.scss";

.typography {
  margin: 0;
  color: var(--flyout-wa-body-text-color);
  word-break: break-word;

  &.withNoWrap {
    @include nowrap();
  }

  &.gutterBottom {
    margin-bottom: toRem(12) !important;
  }

  &.variant-header-1 {
    font-weight: 600;
    font-size: toRem(48);
    line-height: toRem(72);
  }

  &.variant-header-2 {
    font-weight: 600;
    font-size: toRem(32);
    line-height: toRem(48);
  }

  &.variant-header-3 {
    font-weight: 600;
    font-size: toRem(24);
    line-height: toRem(36);
  }

  &.variant-header-4 {
    font-weight: 600;
    font-size: toRem(20);
    line-height: toRem(30);
  }

  &.variant-body-1 {
    font-weight: 400;
    font-size: toRem(16);
    line-height: toRem(24);
  }

  &.variant-body-2 {
    font-weight: 400;
    font-size: toRem(14);
    line-height: toRem(20);
  }

  &.variant-caption {
    font-weight: 400;
    font-size: toRem(12);
    line-height: toRem(18);
  }

  &.color-primary-a {
    color: var(--text-color-primary-a);
  }

  &.color-primary-b {
    color: var(--text-color-primary-b);
  }

  &.color-accent {
    color: var(--text-color-accent);
  }

  &.color-gray {
    color: var(--text-color-gray);
  }

  &.color-info {
    color: var(--text-color-info);
  }

  &.color-success {
    color: var(--text-color-success);
  }

  &.color-warning {
    color: var(--text-color-warning);
  }

  &.color-danger {
    color: var(--text-color-danger);
  }

  &.color-white {
    color: var(--text-color-white);
  }

  &.color-black {
    color: var(--text-color-black);
  }
}
