@import "../../../styles/variables.scss";

.button {
  display: flex;
  cursor: pointer;
  position: relative;

  .imageIcon {
    border-radius: 50%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
}
