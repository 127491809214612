@import "./helpers.scss";

$z-index-background: 1;
$z-index-pin: 2;
$z-index-remote-participant: 102;
$z-index-remote-participant-speaker: 103;
$z-index-pinned-screen: 104;
$z-index-local-participant: 105;
$z-index-screen-share: 400;
$z-index-screen-share-focused: 401;
$z-index-buttons: 500;
$z-index-buttons-exit-builder: 501;
$z-index-browser-window: 570;
$z-index-window-panel: 600;
$z-index-info-popup: 650;
$z-index-dropdowns: 700;
$z-index-recording: 800;
$z-index-floating-call: 900;
$z-index-alert: 1300;
$z-index-tooltip: 1400;
$z-index-drop-down: 1401;
$z-index-loading: 1500;
$z-index-user-away: 1600;

$z-index-floor: 1;
$z-index-floor-floorSelector: 2;
$z-index-floor-floorName: 2;

$mobile: toRem(600);
$tablet: toRem(900);

$color-user-away: #ffbf14;
