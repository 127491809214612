@import "../../../styles/variables.scss";
@import "../../../styles/helpers.scss";

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  outline: none;
  font-weight: 600;
  font-size: toRem(16);
  border-radius: toRem(4);
  min-width: 80px;

  &:disabled {
    background-color: var(--color-lightest-gray) !important;
    color: rgba(var(--GC-0200), 1) !important;
    cursor: default !important;
  }

  .prefix {
    margin-right: toRem(8);
    display: inline-flex;
  }

  .postfix {
    margin-left: toRem(8);
    display: inline-flex;
  }

  &.xs {
    font-weight: 500;
    font-size: toRem(12);
    padding: toRem(4) toRem(8);
    line-height: toRem(18);
  }

  &.small {
    padding: toRem(4) toRem(8);
    font-size: toRem(14);
    line-height: toRem(20);
  }

  &.normal {
    padding: toRem(8) toRem(12);
    line-height: toRem(24);
  }

  &.large {
    padding: toRem(12) toRem(20);
    line-height: toRem(24);
  }

  &.primary-a {
    background: var(--p-button-a-box-color);
    color: var(--p-button-a-text-color);

    .prefix > svg {
      fill: var(--p-button-a-text-color);
    }

    .postfix > svg {
      fill: var(--p-button-a-chevron-color);
    }

    &:hover {
      background: var(--p-button-a-hover-box-color);
      color: var(--p-button-a-hover-text-color);

      .prefix > svg {
        fill: var(--p-button-a-hover-text-color);
      }

      .postfix > svg {
        fill: var(--p-button-a-hover-chevron-color);
      }
    }

    &.outline {
      color: var(--p-button-a-box-color);
      border: 1px solid var(--p-button-a-box-color);
      background: var(--p-button-a-text-color);
    }
  }

  &.primary-b {
    background: var(--p-button-b-box-color);
    color: var(--p-button-b-text-color);

    .prefix > svg {
      fill: var(--p-button-b-text-color);
    }

    .postfix > svg {
      fill: var(--p-button-b-chevron-color);
    }

    &:hover {
      background: var(--p-button-b-hover-box-color);
      color: var(--p-button-b-hover-text-color);

      .prefix > svg {
        fill: var(--p-button-b-hover-text-color);
      }

      .postfix > svg {
        fill: var(--p-button-b-hover-chevron-color);
      }
    }

    &.outline {
      color: var(--p-button-b-box-color);
      border: 1px solid var(--p-button-b-box-color);
      background: var(--p-button-b-text-color);
    }
  }

  &.secondary {
    background: var(--sec-button-box-color);
    color: var(--sec-button-text-color);

    .prefix > svg {
      fill: var(--sec-button-text-color);
    }

    .postfix > svg {
      fill: var(--sec-button-chevron-color);
    }

    &:hover {
      background: var(--sec-button-hover-box-color);
      color: var(--sec-button-hover-text-color);

      .prefix > svg {
        fill: var(--sec-button-hover-text-color);
      }

      .postfix > svg {
        fill: var(--sec-button-hover-chevron-color);
      }
    }

    &.outline {
      color: var(--sec-button-box-color);
      border: 1px solid var(--sec-button-box-color);
      background: var(--sec-button-text-color);
    }
  }

  &.link {
    background: transparent;
    color: var(--link-text-color);

    .prefix > svg {
      fill: var(--link-icon-color);
    }

    .postfix > svg {
      fill: var(--link-chevron-color);
    }

    &:hover {
      background: var(--link-hover-box-color);
      color: var(--link-hover-text-color);

      .prefix > svg {
        fill: var(--link-hover-icon-color);
      }

      .postfix > svg {
        fill: var(--link-hover-chevron-color);
      }
    }
  }

  &.destructive {
    background: transparent;
    color: var(--des-button-text-color);

    .prefix > svg {
      fill: var(--des-button-text-color);
    }

    .postfix > svg {
      fill: var(--link-hover-chevron-color);
    }

    &:hover {
      background: var(--des-button-hover-box-color);
      color: var(--des-button-hover-text-color);
    }
  }

  &.fullWidth {
    width: 100%;
  }

  &.gutterBottom {
    margin-bottom: toRem(12);
  }

  &:active,
  &:focus {
    box-shadow: 0px 0px 0px 3px rgba(var(--GC-0900), 0.15);
  }
}
