@import "../../../styles/variables.scss";

.loading {
  z-index: $z-index-loading;
  background: rgba(0, 0, 0, 0.4) !important;
  backdrop-filter: blur(8px);
}

.content {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  .message {
    margin-top: 20px;
    text-align: center;
  }
}