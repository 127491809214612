@import "./variables.scss";

@mixin fullscreen() {
  width: 100%;
  height: 100%;
}

@mixin nowrap {
  min-width: 0px;
  min-height: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin prettyScroll() {
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: none;
    border: none;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.32);
  }
}

@mixin boxShadow($shadow...) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}