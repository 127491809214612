@import "../../../styles/variables.scss";

svg {
  &.default {
    fill: var(--control-tray-icon-color);

    line {
      stroke: var(--control-tray-icon-color);
    }
  }
  &.danger {
    fill: var(--control-tray-power-icon-color) !important;

    line {
      stroke: var(--control-tray-power-icon-color) !important;
    }
  }
  &.white {
    fill: var(--color-white);

    line {
      stroke: var(--color-white);
    }
  }
  &.black {
    fill: var(--color-dark-gray);

    line {
      stroke: var(--color-dark-gray);
    }
  }
  &.link {
    fill: var(--link-text-color);

    line {
      stroke: var(--link-text-color);
    }
  }
  &.success {
    fill: var(--color-success);

    line {
      stroke: var(--color-success);
    }
  }
  &.primaryB {
    fill: rgba(var(--PCB-0800), 1);

    line {
      stroke: rgba(var(--PCB-0800), 1);
    }
  }
  &.info {
    fill: var(--color-info);

    line {
      stroke: var(--color-info);
    }
  }
  &.warning {
    fill: var(--color-warning);

    line {
      stroke: var(--color-warning);
    }
  }

  &.loading.white {
    path {
      fill: var(--color-white) !important;
      stroke: var(--color-white) !important;
    }
  }
}
