.wrapper {
  position: absolute;
  bottom: -15%;
  z-index: 9999;
  animation: bubbles 6s ease-in-out;
}

@keyframes bubbles {
  0% {
    bottom: -15%;
    margin-left: 0%;
  }
  25% {
    margin-left: 10%;
  }
  50% {
    margin-left: 0%;
  }
  75% {
    margin-left: 10%;
  }
  100% {
    bottom: 115%;
    margin-left: 0%;
  }
}
