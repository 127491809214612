@import "../../../styles/variables.scss";
@import "../../../styles/helpers.scss";

.backdrop {
  z-index: $z-index-alert;

  &.hidden {
    background: transparent !important;
    pointer-events: none;
  }
}

.alertWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: $z-index-alert;
  padding-top: 10vh;
}

.alert {
  border-radius: toRem(16);
  background-color: var(--toast-box-color);
  backdrop-filter: blur(8px);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.24);
  width: 50%;
  min-width: 250px;
  max-width: 360px;
  overflow: hidden;
  padding: toRem(16);
  pointer-events: auto;

  &.inline {
    backdrop-filter: none !important;
    box-shadow: none !important;
    width: 100% !important;
  }

  @media screen and (max-width: $tablet) {
    width: 70vw;
    min-width: unset;
    max-width: unset;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      flex: 1;
      padding-left: toRem(6);
    }
  }

  .content {
    color: var(--toast-body-text-color);
    padding: toRem(8) 0px;
  }

  .actions {
    display: flex;
    justify-content: space-between;
    margin-top: toRem(12);

    .dismiss {

      &.only {
        flex: 1;
        display: flex;
        justify-content: flex-end;
      }
    }

    .main {
      display: flex;
      justify-content: flex-end;

      .button {

        &:first-of-type {
          margin-right: toRem(8);
        }
      }
    }
  }
}