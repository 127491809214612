@import "../../../styles/variables.scss";

.container {
  top: 12px !important;
  right: 0px !important;
  padding: 0px !important;
  z-index: $z-index-alert;
}

.toast {
  padding: 0px !important;
  border-radius: toRem(16);
  //background-color: var(--color-lightest-gray) !important;
  //color: var(--color-primary-a) !important;

  // &,
  // * {
  //   background: var(--color-dark-background) !important;
  //   color: var(--color-dark-text) !important;
  //   font-size: toRem(14) !important;
  // }

  // &>button {
  //   opacity: 0.7 !important;
  // }
}

.toastBody {
  padding: 0px !important;
}