@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.tooltip {
  position: absolute;
  z-index: $z-index-tooltip;
  width: fit-content;
  height: fit-content;
  padding: toRem(4) toRem(12);
  background: var(--control-tray-tooltip-bg);
  color: var(--control-tray-tooltip-text-color);
  white-space: nowrap;
  border-radius: toRem(8);
  font-weight: 500;

  &.inverted {
    color: var(--p-button-a-text-color);
    background: rgba(var(--GC-1000), 0.98);
  }
}
